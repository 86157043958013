import React, { ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Grommet } from 'grommet';
import Helmet from 'react-helmet';
import customTheme from '../utils/customTheme';
import Header from '../components/Header'

const GlobalStyle = createGlobalStyle`
  body {
    margin-top: 10;
    width: 100%;
    margin: 15;
    scroll-behavior: smooth;
  }
  header nav a {
    margin-top: 30;
  }
`;

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => (

  <Grommet
    theme={customTheme}
    background={customTheme.global.colors.background}
    
  >
    <GlobalStyle />
    <Helmet />
    <p>
    <Header/>
    {children}</p>
  </Grommet>
);

export default Layout;
