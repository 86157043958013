import React, { useState, useMemo, useCallback } from 'react';
// import { Link } from 'gatsby-theme-material-ui';
// import { Container, Box, Typography } from '@material-ui/core';
import { Box } from 'grommet';
import { graphql, useStaticQuery, Link } from 'gatsby';
import GithubCorner from '../components/GithubCorner';
import Footer from '../components/Footer';
import ModalEvent from '../components/ModalEvent';
import Month from '../components/Calendar/Month';
import Hero from '../components/Hero';
import Layout from '../components/Layoutcal';
import Eventimage from '../components/Eventimage';
import EventCarousel from '../components/EventCarousel';
import groupEventsByMonth from '../utils/groupEventsByMonth';
import { format } from 'date-fns';

// override this query with your own questions!
const SPREADSHEET_QUERY = graphql`
  query eventsQuery {
    site {
      siteMetadata {
        limitMonthInTheFuture
      }
    }
    allGoogleSheetEventsRow {
      nodes {
        id
        eventName: whatisthename
        date: when
        eventLink: linktotheevent
        place: where
        eventtime: whattime
      }
    }
  }
`;

const CalendarPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalData>();

  const { allGoogleSheetEventsRow, site } = useStaticQuery(SPREADSHEET_QUERY);
  const { limitMonthInTheFuture } = site.siteMetadata;

  const months = useMemo(
    () =>
      groupEventsByMonth(allGoogleSheetEventsRow.nodes, limitMonthInTheFuture),
    [allGoogleSheetEventsRow.nodes, limitMonthInTheFuture],
  );

  const openModal = useCallback((data: ModalData) => {
    setModalData(data);
    setShowModal(true);
  }, []);

  return (
    <Layout>
      <div  style={{
    marginLeft: "5%"
}}>
      <h1>Events </h1>
     <span><p><Eventimage/></p><br/></span>

<span  style={{
    color: "black"
}}>
<p>
Our troop is active <Link to="/annual">throughout the year! </Link>We have the following events coming up. Please contact us if you are interested in attending any of them. Joining us on a hike or service project can be a great way for current Webelos and others interested in becoming Boy Scouts to become familiar with our troop and the scouting experience! 
</p>
</span>
<div>

      <Box id="calendars" animation="fadeIn">
        {months.map((month) => (
          <Month
            key={format(month.startDate, 'MM')}
            openModal={openModal}
            {...month}
          />
        ))}
      </Box>
      {showModal && (
        <ModalEvent onClose={() => setShowModal(false)} {...modalData!} />
      )}
</div>
</div>
      <Footer />
    </Layout>
  );
};

export default CalendarPage;
