const config = require("./data/SiteConfig");
const urljoin = require("url-join");
module.exports = {
  title: 'Gatsby Starter Event Calendar',
  subTitle: 'The easiest option to share events!',
  formLink: 'https://forms.gle/5Kv3XKJBA5g5FWRC7',
  limitMonthInTheFuture: 3,
  theme: require('./themes/light.json'),
  siteUrl: urljoin(config.siteUrl, config.pathPrefix),
  rssMetadata: {
    site_url: urljoin(config.siteUrl, config.pathPrefix),
    feed_url: urljoin(config.siteUrl, config.pathPrefix, config.siteRss),
    title: config.siteTitle,
    description: config.siteDescription,
    image_url: `${urljoin(
      config.siteUrl,
      config.pathPrefix
    )}/logos/logo-48.png`,
    copyright: config.copyright
  }

  



};

